










import { VSnowContainer } from 'vsnow';
import { Component, Vue } from 'vue-property-decorator';

@Component({
  components: {
    VSnow: VSnowContainer,
  },
})
export default class WinterDay extends Vue {
  private snowOptions = {
    color: '#dee4fd',
    size: 8,
    fall_speed: 4.1,
    density: 35,
  };
}
