











import { VSnowContainer } from 'vsnow';
import { Component, Vue } from 'vue-property-decorator';

@Component({
  components: {
    VSnow: VSnowContainer,
  },
})
export default class ValentinesDay extends Vue {
  private snowOptions = {
    color: '#DAF',
    size: 20,
    fall_speed: 4,
    density: 10,
    images: ['/img/valentines-heart.svg'],
  };
}
