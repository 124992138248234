

















import { Component, Vue } from 'vue-property-decorator';

import { User } from '@/store/models/User';
import Layout from '@/components/Layout.vue';
import WinterDay from '@/components/WinterDay.vue';
import ValentinesDay from '@/components/ValentinesDay.vue';
import Header from '@/components/Header/Header.vue';
import CongratulationsModal from '@/components/modals/CongratulationsModal.vue';
import { AuthModule, DepartmentsModule } from '@/store/modules';

@Component({
  components: {
    Layout,
    Header,
    CongratulationsModal,
    SnowFall: WinterDay,
    ValentinesDay,
  },
})
export default class App extends Vue {
  private mods: Modificator = { theme: 'dark' };

  private blockName: string = 'app';

  @AuthModule.namespace.State('currentUser') private currentUser: User;

  @AuthModule.namespace.Getter('isAuthenticated') private isAuthenticated: AuthModule['isAuthenticated'];

  @AuthModule.namespace.Getter('isManagementStaff') private isManagementStaff: AuthModule['isManagementStaff'];

  @AuthModule.namespace.Getter('isStudent') private isStudent: AuthModule['isStudent'];

  @DepartmentsModule.namespace.Action('getDepartments')
  private getDepartments: DepartmentsModule['getDepartments'];

  private get isValentinesDay() {
    const now = new Date();
    return now.getMonth() === 1 && now.getDate() === 14;
  }

  private get isWinterDay() {
    const month = (new Date()).getMonth();
    return month === 11 || month === 0;
  }

  private mounted(): void {
    if (this.isAuthenticated) {
      const {
        needCongratulate,
      } = this.currentUser;
      if (this.isStudent && needCongratulate) {
        this.$modal.show('congratulations');
      }
      if (this.isManagementStaff) {
        this.getDepartments();
      }
    }
  }
}
